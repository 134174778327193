<template>
  <div>
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>数据审核</el-breadcrumb-item>
      <el-breadcrumb-item>表格审核</el-breadcrumb-item>
    </el-breadcrumb>

    <el-card>
      <!-- 合约主管 基础清单 -->
      <div class="baseList" v-if="roleName == '合约主管'">
        <el-row>
          <el-col :span="17" class="colLeft">
            <span class="title">合约主管-基础清单表</span>
            <el-date-picker style="width: 350px" size="small" v-model="dateValue" type="datetimerange"
              range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
              @change="getDate">
            </el-date-picker>
            <el-select @change="getBaseList" class="select2" size="small" v-model="baseQuery.flag">
              <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-table :data="baseList" border stripe style="margin-bottom: 15px">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="excel名称" prop="excelName"></el-table-column>
          <el-table-column label="上报时间" prop="createTime"><template v-slot="scope">{{
              DateFormat(scope.row.createTime)
            }}</template></el-table-column>
          <el-table-column label="上报员" prop="reportUserId"></el-table-column>
          <el-table-column label="操作" min-width="150">
            <template v-slot="scope">
              <el-button type="warning" icon="el-icon-edit" size="mini" @click="showBaseExcel(scope.row)">
                查看excel表</el-button>
              <el-button type="primary" icon="el-icon-download" size="mini" @click="downLoadBaseExcel(scope.row)">
                下载</el-button>
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="acceptBase(scope.row)">通过
              </el-button>
              <el-button type="danger" icon="el-icon-edit" size="mini" @click="rejectBase(scope.row)">不通过
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-config-provider :locale="locale">
          <el-pagination @size-change="baseSizeChange" @current-change="baseCurChange" :current-page="baseQuery.pagenum"
            :page-sizes="[5, 10, 20]" :page-size="baseQuery.pagesize" layout="total, sizes, prev, pager, next, jumper"
            :total="baseTotal">
          </el-pagination>
        </el-config-provider>
        <el-dialog title="数据审核" v-model="rejBaseDiaVisible" width="50%" @close="rejBaseDiaClosed">
          <el-form ref="rejBaseForm" :model="curBase" label-width="80px" width="100%">
            <el-form-item label="驳回原因" prop="reason">
              <el-input type="text" v-model="curBase.reason"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="rejBaseDiaVisible = false">取消</el-button>
              <el-button type="primary" @click="rejBase">确定</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
      <!-- 合约主管 甩项 -->
      <div class="jilt" v-if="roleName == '合约主管'">
        <el-row>
          <el-col :span="17" class="colLeft">
            <span class="title">合约主管-甩项工程量</span>
            <el-date-picker style="width: 350px" size="small" v-model="dateValue" type="datetimerange"
              range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
              @change="getDate">
            </el-date-picker>
            <el-select @change="getJilt" class="select2" size="small" v-model="jiltQuery.flag">
              <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
        </el-row>
        <el-table :data="jilt" border stripe style="margin-bottom: 15px">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="excel名称" prop="excelName"></el-table-column>
          <el-table-column label="上报时间" prop="createTime"><template
              v-slot="scope">{{DateFormat(scope.row.createTime)}}</template></el-table-column>
          <el-table-column label="上报员" prop="reportUserId"></el-table-column>
          <el-table-column label="操作" min-width="150">
            <template v-slot="scope">
              <el-button type="warning" icon="el-icon-edit" size="mini" @click="showJiltExcel(scope.row)">
                查看excel表</el-button>
              <el-button type="primary" icon="el-icon-download" size="mini" @click="downLoadJiltExcel(scope.row)">
                下载</el-button>
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="acceptJilt(scope.row)">通过
              </el-button>
              <el-button type="danger" icon="el-icon-edit" size="mini" @click="rejectJilt(scope.row)">不通过
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-config-provider :locale="locale">
          <el-pagination @size-change="jiltSizeChange" @current-change="jiltCurChange" :current-page="jiltQuery.pagenum"
            :page-sizes="[5, 10, 20]" :page-size="jiltQuery.pagesize" layout="total, sizes, prev, pager, next, jumper"
            :total="jiltTotal">
          </el-pagination>
        </el-config-provider>
        <el-dialog title="数据审核" v-model="rejJiltDiaVisible" width="50%" @close="rejJiltDiaClosed">
          <el-form ref="rejJiltForm" :model="curJilt" label-width="80px" width="100%">
            <el-form-item label="驳回原因" prop="reason">
              <el-input type="text" v-model="curJilt.reason"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="rejJiltDiaVisible = false">取消</el-button>
              <el-button type="primary" @click="rejJilt">确定</el-button>
            </span>
          </template>
        </el-dialog>
      </div>

      <!-- 施工主管 进度 -->
      <div class="progress" v-if="roleName == '施工主管'">
        <el-row>
          <el-col :span="17" class="colLeft">
            <span class="title">施工主管-进度表</span>
            <el-date-picker style="width: 350px" size="small" v-model="dateValue" type="datetimerange"
              range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
              @change="getDate">
            </el-date-picker>
            <el-select @change="getProgress" class="select2" size="small" v-model="proQuery.flag">
              <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!-- <el-col :span="7">
						<el-input placeholder="请输入excel表名称" v-model="proQuery.query" @keyup.enter="getProgress()"
							clearable @clear="getProgress()">
							<template #append>
								<el-button icon="el-icon-search" @click="getProgress()"></el-button>
							</template>
						</el-input>
					</el-col> -->
        </el-row>
        <el-table :data="progress" border stripe style="margin-bottom: 15px">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="excel名称" prop="excelName"></el-table-column>
          <el-table-column label="上报时间" prop="createTime"><template v-slot="scope">{{
              DateFormat(scope.row.createTime)
            }}</template></el-table-column>
          <el-table-column label="上报员" prop="reportUserId"></el-table-column>
          <el-table-column label="操作" min-width="150">
            <template v-slot="scope">
              <el-button type="warning" icon="el-icon-edit" size="mini" @click="showProExcel(scope.row)">
                查看excel表</el-button>
              <el-button type="primary" icon="el-icon-download" size="mini" @click="downLoadProExcel(scope.row)">
                下载</el-button>
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="acceptPro(scope.row)">通过
              </el-button>
              <el-button type="danger" icon="el-icon-edit" size="mini" @click="rejectPro(scope.row)">不通过
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-config-provider :locale="locale">
          <el-pagination @size-change="proSizeChange" @current-change="proCurChange" :current-page="proQuery.pagenum"
            :page-sizes="[5, 10, 20]" :page-size="proQuery.pagesize" layout="total, sizes, prev, pager, next, jumper"
            :total="proTotal">
          </el-pagination>
        </el-config-provider>
        <el-dialog title="数据审核" v-model="rejProDiaVisible" width="50%" @close="rejProDiaClosed">
          <el-form ref="rejProForm" :model="curPro" label-width="80px" width="100%">
            <el-form-item label="驳回原因" prop="reason">
              <el-input type="text" v-model="curPro.reason"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="rejProDiaVisible = false">取消</el-button>
              <el-button type="primary" @click="rejPro">确定</el-button>
            </span>
          </template>
        </el-dialog>
      </div>

      <!-- 资料主管 资料 -->
      <div class="document" v-if="roleName == '资料主管'">
        <el-row>
          <el-col :span="17" class="colLeft">
            <span class="title">资料主管-资料表</span>
            <el-date-picker style="width: 350px" size="small" v-model="dateValue" type="datetimerange"
              range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
              @change="getDate">
            </el-date-picker>
            <el-select @change="getDocument" class="select2" size="small" v-model="docQuery.flag">
              <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!-- <el-col :span="7">
						<el-input placeholder="请输入excel表名称" v-model="docQuery.query" @keyup.enter="getDocument()"
							clearable @clear="getDocument()">
							<template #append>
								<el-button icon="el-icon-search" @click="getDocument()"></el-button>
							</template>
						</el-input>
					</el-col> -->
        </el-row>
        <el-table :data="document" border stripe style="margin-bottom: 15px">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="excel名称" prop="excelName"></el-table-column>
          <el-table-column label="上报时间" prop="createTime"><template v-slot="scope">{{
              DateFormat(scope.row.createTime)
            }}</template></el-table-column>
          <el-table-column label="上报员" prop="reportUserId"></el-table-column>
          <el-table-column label="操作" min-width="150">
            <template v-slot="scope">
              <el-button type="warning" icon="el-icon-edit" size="mini" @click="showDocExcel(scope.row)">
                查看excel表</el-button>
              <el-button type="primary" icon="el-icon-download" size="mini" @click="downLoadDocExcel(scope.row)">
                下载</el-button>
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="acceptDoc(scope.row)">通过
              </el-button>
              <el-button type="danger" icon="el-icon-edit" size="mini" @click="rejectDoc(scope.row)">不通过
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-config-provider :locale="locale">
          <el-pagination @size-change="docSizeChange" @current-change="docCurChange" :current-page="docQuery.pagenum"
            :page-sizes="[5, 10, 20]" :page-size="docQuery.pagesize" layout="total, sizes, prev, pager, next, jumper"
            :total="docTotal">
          </el-pagination>
        </el-config-provider>
        <el-dialog title="数据审核" v-model="rejDocDiaVisible" width="50%" @close="rejDocDiaClosed">
          <el-form ref="rejDocForm" :model="curDoc" label-width="80px" width="100%">
            <el-form-item label="驳回原因" prop="reason">
              <el-input type="text" v-model="curDoc.reason"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="rejDocDiaVisible = false">取消</el-button>
              <el-button type="primary" @click="rejDoc">确定</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
      <!-- 资料主管 资料问题表审核 -->
      <div class="documentQus" v-if="roleName == '资料主管'">
        <el-row>
          <el-col :span="17" class="colLeft"><span class="title">资料主管-资料问题表</span>
            <el-date-picker style="width: 350px" size="small" v-model="dateValueQus" type="datetimerange"
              range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
              @change="getDateQus">
            </el-date-picker>
            <el-select @change="getDocumentQus" class="select2" size="small" v-model="docQueryQus.flag">
              <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!-- <el-col :span="7">
						<el-input placeholder="请输入excel表名称" v-model="docQueryQus.query" @keyup.enter="getDocumentQus()"
							clearable @clear="getDocumentQus()">
							<template #append>
								<el-button icon="el-icon-search" @click="getDocumentQus()"></el-button>
							</template>
						</el-input>
					</el-col> -->
        </el-row>
        <el-table :data="documentQus" border stripe style="margin-bottom: 15px">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="excel名称" prop="excelName"></el-table-column>
          <el-table-column label="上报时间" prop="createTime"><template v-slot="scope">{{
              DateFormat(scope.row.createTime)
            }}</template></el-table-column>
          <el-table-column label="上报员" prop="reportUserId"></el-table-column>
          <el-table-column label="操作" min-width="150">
            <template v-slot="scope">
              <el-button type="warning" icon="el-icon-edit" size="mini" @click="showDocExcel(scope.row)">查看excel表
              </el-button>
              <el-button type="primary" icon="el-icon-download" size="mini" @click="downLoadDocExcelQus(scope.row)">
                下载</el-button>
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="acceptDocQus(scope.row)">
                通过</el-button>
              <el-button type="danger" icon="el-icon-edit" size="mini" @click="rejectDocQus(scope.row)">
                不通过</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-config-provider :locale="locale">
          <el-pagination @size-change="docSizeChangeQus" @current-change="docCurChangeQus"
            :current-page="docQueryQus.pagenum" :page-sizes="[5, 10, 20]" :page-size="docQueryQus.pagesize"
            layout="total, sizes, prev, pager, next, jumper" :total="docTotalQus">
          </el-pagination>
        </el-config-provider>
        <el-dialog title="数据审核" v-model="rejDocDiaVisibleQus" width="50%" @close="rejDocDiaClosedQus">
          <el-form ref="rejDocFormQus" :model="curDocQus" label-width="80px" width="100%">
            <el-form-item label="驳回原因" prop="reason">
              <el-input type="text" v-model="curDocQus.reason"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="rejDocDiaVisibleQus = false">取消</el-button>
              <el-button type="primary" @click="rejDocQus">确定</el-button>
            </span>
          </template>
        </el-dialog>
      </div>

      <!-- 试验主管 试验检测表 -->
      <div class="experiment" v-if="roleName == '试验主管'">
        <el-row>
          <el-col :span="17" class="colLeft">
            <span class="title">试验主管-试验检测表</span>
            <el-date-picker style="width: 350px" size="small" v-model="dateValue" type="datetimerange"
              range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
              @change="getDate">
            </el-date-picker>
            <el-select @change="getExperiment" class="select2" size="small" v-model="expQuery.flag">
              <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!-- <el-col :span="7">
						<el-input placeholder="请输入excel表名称" v-model="expQuery.query" @keyup.enter="getExperiment()"
							clearable @clear="getExperiment()">
							<template #append>
								<el-button icon="el-icon-search" @click="getExperiment()"></el-button>
							</template>
						</el-input>
					</el-col> -->
        </el-row>
        <el-table :data="experiment" border stripe style="margin-bottom: 15px">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="excel名称" prop="excelName"></el-table-column>
          <el-table-column label="上报时间" prop="createTime"><template v-slot="scope">{{
              DateFormat(scope.row.createTime)
            }}</template></el-table-column>
          <el-table-column label="上报员" prop="reportUserId"></el-table-column>
          <el-table-column label="操作" min-width="150">
            <template v-slot="scope">
              <el-button type="warning" icon="el-icon-edit" size="mini" @click="showExpExcel(scope.row)">
                查看excel表</el-button>
              <el-button type="primary" icon="el-icon-download" size="mini" @click="downloadExpExcel(scope.row)">
                下载</el-button>
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="acceptExp(scope.row)">通过
              </el-button>
              <el-button type="danger" icon="el-icon-edit" size="mini" @click="rejectExp(scope.row)">不通过
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-config-provider :locale="locale">
          <el-pagination @size-change="expSizeChange" @current-change="expCurChange" :current-page="expQuery.pagenum"
            :page-sizes="[5, 10, 20]" :page-size="expQuery.pagesize" layout="total, sizes, prev, pager, next, jumper"
            :total="expTotal">
          </el-pagination>
        </el-config-provider>
        <el-dialog title="数据审核" v-model="rejExpDiaVisible" width="50%" @close="rejExpDiaClosed">
          <el-form ref="rejExpForm" :model="curExp" label-width="80px" width="100%">
            <el-form-item label="驳回原因" prop="reason">
              <el-input type="text" v-model="curExp.reason"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="rejExpDiaVisible = false">取消</el-button>
              <el-button type="primary" @click="rejExp">确定</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
      <!-- 试验主管 试验检测不合格表 -->
      <div class="experimentQus" v-if="roleName == '试验主管'">
        <el-row>
          <el-col :span="17" class="colLeft"><span class="title">试验主管-试验检测不合格表</span>
            <el-date-picker style="width: 350px" size="small" v-model="dateValueQus" type="datetimerange"
              range-separator="到" start-placeholder="请选择开始时间" end-placeholder="请选择截至时间" :editable="false"
              @change="getDateQus">
            </el-date-picker>
            <el-select @change="getExperimentQus" class="select2" size="small" v-model="expQueryQus.flag">
              <el-option v-for="item in dateType" :key="item.id" :label="item.status" :value="item.id">
              </el-option>
            </el-select>
          </el-col>
          <!-- <el-col :span="7">
						<el-input placeholder="请输入excel表名称" v-model="docQueryQus.query"
							@keyup.enter="getExperimentQus()" clearable @clear="getExperimentQus()">
							<template #append>
								<el-button icon="el-icon-search" @click="getExperimentQus()"></el-button>
							</template>
						</el-input>
					</el-col> -->
        </el-row>
        <el-table :data="experimentQus" border stripe style="margin-bottom: 15px">
          <el-table-column type="index" label="序号"></el-table-column>
          <el-table-column label="excel名称" prop="excelName"></el-table-column>
          <el-table-column label="上报时间" prop="createTime"><template v-slot="scope">{{
              DateFormat(scope.row.createTime)
            }}</template></el-table-column>
          <el-table-column label="上报员" prop="reportUserId"></el-table-column>
          <el-table-column label="操作" min-width="150">
            <template v-slot="scope">
              <el-button type="warning" icon="el-icon-edit" size="mini" @click="showExpExcel(scope.row)">查看excel表
              </el-button>
              <el-button type="primary" icon="el-icon-download" size="mini" @click="downloadExpExcelQus(scope.row)">
                下载</el-button>
              <el-button type="primary" icon="el-icon-edit" size="mini" @click="acceptExpQus(scope.row)">
                通过</el-button>
              <el-button type="danger" icon="el-icon-edit" size="mini" @click="rejectExpQus(scope.row)">
                不通过</el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-config-provider :locale="locale">
          <el-pagination @size-change="expSizeChangeQus" @current-change="expCurChangeQus"
            :current-page="expQueryQus.pagenum" :page-sizes="[5, 10, 20]" :page-size="expQueryQus.pagesize"
            layout="total, sizes, prev, pager, next, jumper" :total="expTotalQus">
          </el-pagination>
        </el-config-provider>
        <el-dialog title="数据审核" v-model="rejExpDiaVisibleQus" width="50%" @close="rejExpDiaClosedQus">
          <el-form ref="rejExpFormQus" :model="curExpQus" label-width="80px" width="100%">
            <el-form-item label="驳回原因" prop="reason">
              <el-input type="text" v-model="curExpQus.reason"></el-input>
            </el-form-item>
          </el-form>
          <template #footer>
            <span class="dialog-footer">
              <el-button @click="rejExpDiaVisibleQus = false">取消</el-button>
              <el-button type="primary" @click="rejExpQus">确定</el-button>
            </span>
          </template>
        </el-dialog>
      </div>
    </el-card>
  </div>
</template>

<script>
  import zhCn from "element-plus/lib/locale/lang/zh-cn";
  import dateFormatFun from "../../plugins/dateFormat";
  export default {
    data() {
      return {
        document: [],
        docQuery: {
          query: "",
          pagenum: 1,
          pagesize: 5,
          flag: 0,
          startTime: "",
          endTime: "",
        },
        dateType: [{
            id: 0,
            status: "全部",
          },
          {
            id: 1,
            status: "近1周",
          },
          {
            id: 2,
            status: "近1月",
          },
          {
            id: 3,
            status: "近3月",
          },
        ],
        dateValue: [null, null],
        docTotal: 0,
        rejDocDiaVisible: false,
        curDoc: {},

        // 资料主管问题表格审核
        dateValueQus: [null, null],
        documentQus: [],
        docQueryQus: {
          flag: 0,
          startTime: "",
          endTime: "",
          query: "",
          pagenum: 1,
          pagesize: 5,
        },
        docTotalQus: 0,
        rejDocDiaVisibleQus: false,
        curDocQus: {},

        experiment: [],
        expQuery: {
          query: "",
          pagenum: 1,
          pagesize: 5,
          flag: 0,
          startTime: "",
          endTime: "",
        },
        expTotal: 0,
        rejExpDiaVisible: false,
        curExp: {},
        // 试验主管问题表格审核
        experimentQus: [],
        expQueryQus: {
          startTime: "",
          endTime: "",
          flag: 0,
          query: "",
          pagenum: 1,
          pagesize: 5,
        },
        expTotalQus: 0,
        rejExpDiaVisibleQus: false,
        curExpQus: {},

        baseList: [],
        baseQuery: {
          query: "",
          pageNum: 1,
          pageSize: 5,
          flag: 0,
          startTime: "",
          endTime: "",
        },
        baseTotal: 0,
        rejBaseDiaVisible: false,
        curBase: {},

        jilt: [],
        jiltQuery: {
          query: "",
          pageNum: 1,
          pageSize: 5,
          flag: 0,
          startTime: "",
          endTime: "",
        },
        jiltTotal: 0,
        rejJiltDiaVisible: false,
        curJilt: {},

        progress: [],
        proQuery: {
          startTime: "",
          endTime: "",
          flag: 0,
          query: "",
          pagenum: 1,
          pagesize: 5,
        },
        proTotal: 0,
        rejProDiaVisible: false,
        curPro: {},

        roleName: "",
      };
    },
    setup() {
      return {
        locale: zhCn,
      };
    },
    mounted() {
      this.roleName = window.sessionStorage.getItem("roleName");

      if (this.roleName == "资料主管") {
        this.getDocument();
        this.getDocumentQus();
      }
      if (this.roleName == "试验主管") {
        this.getExperiment();
        this.getExperimentQus();
      }
      if (this.roleName == "施工主管") {
        this.getProgress();
      }
      if (this.roleName == "合约主管") {
        this.getBaseList();
        this.getJilt();
      }
    },
    computed: {
      DateFormat() {
        return function(date) {
          return dateFormatFun(date, 2);
        };
      },
    },
    methods: {
      getDate(e) {
        let obj;
        let fun;
        let obj1;
        let fun1;
        if (this.roleName == "资料主管") {
          obj = this.docQuery;
          fun = this.getDocument;
        } else if (this.roleName == "试验主管") {
          obj = this.expQuery;
          fun = this.getExperiment;
        } else if (this.roleName == "施工主管") {
          obj = this.proQuery;
          fun = this.getProgress;
        } else if (this.roleName == "合约主管") {
          obj = this.baseQuery;
          fun = this.getBaseList
          obj1 = this.jiltQuery;
          fun1 = this.getJilt
        }
        if (e) {
          obj.startTime = dateFormatFun(e[0], 2);
          obj.endTime = dateFormatFun(e[1], 2);
          obj1.startTime = dateFormatFun(e[0], 2);
          obj1.endTime = dateFormatFun(e[1], 2);
        } else {
          obj.startTime = "";
          obj.endTime = "";
          obj1.startTime = "";
          obj1.endTime = "";
        }
        fun();
        fun1();
      },
      getDateQus(e) {
        let obj;
        let fun;
        if (this.roleName == "资料主管") {
          obj = this.docQueryQus;
          fun = this.getDocumentQus;
        } else if (this.roleName == "试验主管") {
          obj = this.expQueryQus;
          fun = this.getExperimentQus;
        } else if (this.roleName == "施工主管") {
          obj = this.proQuery;
          fun = this.getProgress;
        } else if (this.roleName == "合约主管") {}
        if (e) {
          obj.startTime = dateFormatFun(e[0], 2);
          obj.endTime = dateFormatFun(e[1], 2);
        } else {
          obj.startTime = "";
          obj.endTime = "";
        }
        fun();
      },
      // 资料表
      async getDocument() {
        this.docQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "documentSupervisor/getReviewDocumentExcel", {
            params: this.docQuery,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.document = res.data.list;
        this.docTotal = res.data.total;
      },
      docSizeChange(newSize) {
        this.docQuery.pagesize = newSize;
        this.getDocument();
      },
      docCurChange(newPage) {
        this.docQuery.pagenum = newPage;
        this.getDocument();
      },
      async acceptDoc(document) {
        console.log(document);
        const confirmRes = await this.$confirm("确认通过该条数据？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => console.log(err));
        if (confirmRes !== "confirm") {
          return this.$message.info("取消通过");
        }
        let formData = new FormData();
        formData.append("id", document.id);
        const {
          data: res
        } = await this.$http.post(
          "documentSupervisor/acceptDocumentExcel",
          formData
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.$message.success(res.msg);
        this.getDocument();
      },
      rejectDoc(document) {
        this.curDoc = document;
        this.rejDocDiaVisible = true;
      },
      rejDocDiaClosed() {
        this.$refs.rejDocForm.resetFields();
      },
      async rejDoc() {
        let formData = new FormData();
        formData.append("id", this.curDoc.id);
        formData.append("reason", this.curDoc.reason);
        const {
          data: res
        } = await this.$http.post(
          "documentSupervisor/rejectDocumentExcel",
          formData
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.$message.success(res.msg);
        this.rejDocDiaVisible = false;
        this.getDocument();
      },
      downLoadDocExcel(excel) {
        this.$message.info("已下载");
        let cid = excel.id;
        let url =
          this.$http.defaults.baseURL +
          "documentSupervisor/getDocumentExcelById/" +
          cid;
        this.DownloadExcel(url);
      },
      async showDocExcel(excel) {
        let id = excel.id;
        let url =
          this.$http.defaults.baseURL +
          "documentSupervisor/getDocumentExcelUrl/" +
          id;
        this.$http.get(url, {
          id
        }).then(res => {
          let doc =
            `https://view.officeapps.live.com/op/view.aspx?src=${this.$http.defaults.baseURL+res.data.msg}`
          window.open(doc)
        })
      },

      // 资料主管问题表格审核
      async getDocumentQus() {
        this.docQueryQus.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "documentSupervisor/getReviewProblemDocumentExcel", {
            params: this.docQueryQus
          }
        );
        console.log(res);
        if (res.code !== 200) return this.$message.error(res.msg);

        console.log("数据审核/表格审核-资料主管-资料表", res);
        this.documentQus = res.data.list;
        this.docTotalQus = res.data.total;
      },
      docSizeChangeQus(newSize) {
        this.docQueryQus.pagesize = newSize;
        this.getDocumentQus();
      },
      docCurChangeQus(newPage) {
        this.docQueryQus.pagenum = newPage;
        this.getDocumentQus();
      },
      async acceptDocQus(document) {
        console.log(document);
        const confirmRes = await this.$confirm("确认通过该条数据？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => console.log(err));
        if (confirmRes !== "confirm") {
          return this.$message.info("取消通过");
        }
        let formData = new FormData();
        formData.append("id", document.id);
        console.log(formData);
        const {
          data: res
        } = await this.$http.post(
          "documentSupervisor/acceptProblemDocumentExcel",
          formData
        );
        console.log(res);
        if (res.code !== 200) return this.$message.error(res.msg);
        this.$message.success(res.msg);
        this.getDocumentQus();
      },
      rejectDocQus(document) {
        this.curDocQus = document;
        console.log(this.curDocQus.id);
        this.rejDocDiaVisibleQus = true;
      },
      rejDocDiaClosedQus() {
        this.$refs.rejDocFormQus.resetFields();
      },
      async rejDocQus() {
        let formData = new FormData();
        formData.append("id", this.curDocQus.id);
        formData.append("reason", this.curDocQus.reason);
        const {
          data: res
        } = await this.$http.post(
          "documentSupervisor/rejectProblemDocumentExcel",
          formData
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.$message.success(res.msg);
        this.rejDocDiaVisibleQus = false;
        this.getDocumentQus();
      },
      downLoadDocExcelQus(excel) {
        this.$message.info("已下载");
        let cid = excel.id;
        console.log(cid);
        let url =
          this.$http.defaults.baseURL +
          "documentSupervisor/getProblemDocumentExcelById/" +
          cid;
        this.DownloadExcel(url);
      },

      // 试验检测表
      async getExperiment() {
        this.expQuery.curItemId = window.sessionStorage.getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "experimentSupervisor/getReviewExperimentExcel", {
            params: this.expQuery,
          }
        );
        if (res.code !== 200) return this.$message.error(res.msg);
        this.experiment = res.data.list;
        console.log(this.experiment);
        this.expTotal = res.data.total;
      },
      expSizeChange(newSize) {
        this.expQuery.pagesize = newSize;
        this.getExperiment();
      },
      expCurChange(newPage) {
        this.expQuery.pagenum = newPage;
        this.getExperiment();
      },
      async acceptExp(experiment) {
        const confirmRes = await this.$confirm("确认通过该条数据？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => console.log(err));
        if (confirmRes !== "confirm") {
          return this.$message.info("取消通过");
        }
        let formData = new FormData();
        formData.append("id", experiment.id);
        // console.log(experiment)
        const {
          data: res
        } = await this.$http.post(
          "experimentSupervisor/acceptExperimentExcel",
          formData
        );
        if (res.code !== 200) {
          console.log(res);
          return this.$message.error(res.msg);
        }
        this.$message.success(res.msg);
        this.getExperiment();
      },
      rejectExp(experiment) {
        this.curExp = experiment;
        this.rejExpDiaVisible = true;
      },
      rejExpDiaClosed() {
        this.$refs.rejExpForm.resetFields();
      },
      async rejExp() {
        let formData = new FormData();
        formData.append("id", this.curExp.id);
        formData.append("reason", this.curExp.reason);
        const {
          data: res
        } = await this.$http.post(
          "experimentSupervisor/rejectExperimentExcel",
          formData
        );
        console.log(res);
        if (res.code !== 200) return this.$message.error(res.msg);
        this.$message.success(res.msg);
        this.rejExpDiaVisible = false;
        this.getExperiment();
      },
      showExpExcel(excel) {
        let id = excel.id;
        let url =
          this.$http.defaults.baseURL +
          "experimentSupervisor/getExperimentExcelUrl/" +
          id;
        this.$http.get(url, {
          id
        }).then(res => {
          let doc =
            `https://view.officeapps.live.com/op/view.aspx?src=${this.$http.defaults.baseURL+res.data.msg}`
          window.open(doc)
        })
      },
      downloadExpExcel(excel) {
        let cid = excel.id;
        let url =
          this.$http.defaults.baseURL +
          "experimentSupervisor/getExperimentExcelById/" +
          cid;
        this.DownloadExcel(url);

      },
      // 试验主管表格操作 检测不合格表
      async getExperimentQus() {
        this.expQueryQus.curItemId = window.sessionStorage.getItem(
          "activeItem");
        const {
          data: res
        } = await this.$http.get(
          "experimentSupervisor/getReviewExperimentNoPassExcel", {
            params: this.expQueryQus
          }
        );
        console.log(res.msg)
        if (res.code !== 200) return this.$message.error(res.msg);
        this.experimentQus = res.data.list;
        console.log(this.experimentQus);
        this.expTotalQus = res.data.total;
      },
      expSizeChangeQus(newSize) {
        this.expQueryQus.pagesize = newSize;
        this.getExperimentQus();
      },
      expCurChangeQus(newPage) {
        this.expQueryQus.pagenum = newPage;
        this.getExperimentQus();
      },
      async acceptExpQus(experiment) {
        const confirmRes = await this.$confirm("确认通过该条数据？", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        }).catch((err) => console.log(err));
        if (confirmRes !== "confirm") {
          return this.$message.info("取消通过");
        }
        let formData = new FormData();
        formData.append("id", experiment.id);
        console.log(experiment.id);
        const {
          data: res
        } = await this.$http.post(
          "experimentSupervisor/acceptExperimentNoPassExcel",
          formData
        );
        if (res.code !== 200) {
          console.log(res);
          return this.$message.error(res.msg);
        }
        this.$message.success(res.msg);
        this.getExperimentQus();
      },
      rejectExpQus(experiment) {
        this.curExpQus = experiment;
        this.rejExpDiaVisibleQus = true;
      },
      rejExpDiaClosedQus() {
        this.$refs.rejExpFormQus.resetFields();
      },
      async rejExpQus() {
        let formData = new FormData();
        formData.append("id", this.curExpQus.id);
        formData.append("reason", this.curExpQus.reason);
        const {
          data: res
        } = await this.$http.post(
          "experimentSupervisor/rejectExperimentNoPassExcel",
          formData
        );
        console.log(res);
        if (res.code !== 200) return this.$message.error(res
          .msg);
        this.$message.success(res.msg);
        this.rejExpDiaVisibleQus = false;
        this.getExperimentQus();
      },
      downloadExpExcelQus(excel) {
        let cid = excel.id;
        let url =
          this.$http.defaults.baseURL +
          "experimentSupervisor/getExperimentNoPassExcelById/" +
          cid;
        this.DownloadExcel(url);
      },
      //下载表格
      DownloadExcel(url) {
        let xhr = new XMLHttpRequest();
        xhr.open("get", url);
        xhr.setRequestHeader("Content-Type",
          "application/json;charset=UTF-8");
        xhr.setRequestHeader("satoken", window.sessionStorage
          .getItem("satoken"));
        xhr.setRequestHeader(
          "curItemId",
          window.sessionStorage.getItem("activeItem")
        );
        xhr.responseType = "blob";
        xhr.send("");
        xhr.onreadystatechange = function() {
          if (xhr.readyState === 4 && xhr.status ===
            200) {
            const blob = new Blob([xhr.response]);
            let url = window.URL.createObjectURL(blob);
            //创建一个a标签元素，设置下载属性，点击下载，最后移除该元素
            let link = document.createElement("a");
            link.href = url;
            link.style.display = "none";
            //判断是否成功
            // const code = xhr.getResponseHeader('Sa-Code')
            // const msg = decodeURIComponent(xhr.getResponseHeader('Sa-Msg'))
            // if(code!='200'){this.$message.warning("暂无此文件");return;}
            //取出下载文件名
            const contentDisposition = xhr
              .getResponseHeader(
                "Content-Disposition"
              );
            if (contentDisposition == null) {
              return alert("表格不存在");
            }
            const downloadFileName =
              decodeURIComponent(
                contentDisposition.split("=")[1],
                "GBK"
              );
            link.setAttribute("download",
              downloadFileName);
            link.click();
            window.URL.revokeObjectURL(url);
          }
        };
      },

      // 基础清单表
      async getBaseList() {
        this.baseQuery.curItemId = window.sessionStorage
          .getItem("activeItem");
        const {
          data: res
        } = await this.$http.get(
          "contractSupervisor/getReviewBaseExcel", {
            params: this.baseQuery,
          }
        );
        if (res.code !== 200) return this.$message.error(
          res.msg);
        this.baseList = res.data.list;
        this.baseTotal = res.data.total;
      },
      baseSizeChange(newSize) {
        this.baseQuery.pagesize = newSize;
        this.getBaseList();
      },
      baseCurChange(newPage) {
        this.baseQuery.pagenum = newPage;
        this.getBaseList();
      },
      async acceptBase(baseList) {
        const confirmRes = await this.$confirm(
          "确认通过该条数据？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).catch((err) => console.log(err));
        if (confirmRes !== "confirm") {
          return this.$message.info("取消通过");
        }
        let formData = new FormData();
        formData.append("id", baseList.id);
        const {
          data: res
        } = await this.$http.post(
          "contractSupervisor/acceptBaseExcel",
          formData
        );
        console.log(res);
        if (res.code !== 200) return this.$message
          .error(res.msg);
        console.log(confirmRes);
        this.$message.success(res.msg);
        this.getBaseList();
      },
      rejectBase(baseList) {
        this.curBase = baseList;
        this.rejBaseDiaVisible = true;
      },
      rejBaseDiaClosed() {
        this.$refs.rejBaseForm.resetFields();
      },
      async rejBase() {
        let formData = new FormData();
        formData.append("id", this.curBase.id);
        formData.append("reason", this.curBase
          .reason);
        const {
          data: res
        } = await this.$http.post(
          "contractSupervisor/rejectBaseExcel",
          formData
        );
        if (res.code !== 200) return this.$message
          .error(res.msg);
        this.$message.success(res.msg);
        this.rejBaseDiaVisible = false;
        this.getBaseList();
      },
      showBaseExcel(excel) {
        let id = excel.id;
        let url =
          this.$http.defaults.baseURL +
          "contractSupervisor/getBaseExcelUrl/" +
          id;
        this.$http.get(url, {
          id
        }).then(res => {
          let doc =
            `https://view.officeapps.live.com/op/view.aspx?src=${this.$http.defaults.baseURL+res.data.msg}`
          window.open(doc)
        })
      },
      downLoadBaseExcel(excel) {
        let cid = excel.id;
        let url = this.$http.defaults.baseURL +
          "contractor/exportBaseListExcel";
        this.$message.warning("在线预览 基础清单 excel");
        this.DownloadExcel(url);
      },

      // 甩项工程量
      async getJilt() {
        this.jiltQuery.curItemId = window
          .sessionStorage.getItem(
            "activeItem");
        const {
          data: res
        } = await this.$http.get(
          "contractSupervisor/getReviewJiltExcel", {
            params: this.jiltQuery,
          }
        );
        if (res.code !== 200) return this
          .$message.error(res.msg);
        this.jilt = res.data.list;
        this.jiltTotal = res.data.total;
      },
      jiltSizeChange(newSize) {
        this.jiltQuery.pagesize = newSize;
        this.getJilt();
      },
      jiltCurChange(newPage) {
        this.jiltQuery.pagenum = newPage;
        this.getJilt();
      },
      async acceptJilt(jilt) {
        const confirmRes = await this
          .$confirm("确认通过该条数据？", "提示", {
            confirmButtonText: "确定",
            cancelButtonText: "取消",
            type: "warning",
          }).catch((err) => console.log(
            err));
        if (confirmRes !== "confirm") {
          return this.$message.info(
            "取消通过");
        }
        let formData = new FormData();
        formData.append("id", jilt.id);
        const {
          data: res
        } = await this.$http.post(
          "contractSupervisor/acceptJiltExcel",
          formData
        );
        if (res.code !== 200) return this
          .$message.error(res.msg);
        this.$message.success(res.msg);
        this.getJilt();
      },
      rejectJilt(jilt) {
        this.curJilt = jilt;
        this.rejJiltDiaVisible = true;
      },
      rejJiltDiaClosed() {
        this.$refs.rejJiltForm
          .resetFields();
      },
      async rejJilt() {
        let formData = new FormData();
        formData.append("id", this
          .curJilt.id);
        formData.append("reason", this
          .curJilt.reason);
        const {
          data: res
        } = await this.$http.post(
          "contractSupervisor/rejectJiltExcel",
          formData
        );
        if (res.code !== 200)
          return this.$message.error(
            res.msg);
        this.$message.success(res.msg);
        this.rejJiltDiaVisible = false;
        this.getJilt();
      },
      showJiltExcel(excel) {
        let id = excel.id;
        let url =
          this.$http.defaults.baseURL +
          "contractSupervisor/getJiltExcelUrl/" +
          id;
        this.$http.get(url, {
          id
        }).then(res => {
          let doc =
            `https://view.officeapps.live.com/op/view.aspx?src=${this.$http.defaults.baseURL+res.data.msg}`
          window.open(doc)
        })
      },
      downLoadJiltExcel(excel) {
        let cid = excel.id;
        let url =
          this.$http.defaults
          .baseURL +
          "contractSupervisor/getJiltExcelById/" +
          cid;
        this.$message.warning(
          "在线预览 甩项 excel");
        this.DownloadExcel(url);
      },

      // 进度表
      async getProgress() {
        this.proQuery.curItemId =
          window.sessionStorage
          .getItem("activeItem");
        // this.proQuery.curItemId = 3;
        const {
          data: res
        } = await this.$http.get(
          "progressSupervisor/getReviewProgressExcel", {
            params: this
              .proQuery,
          }
        ); //----------------
        if (res.code !== 200)
          return this.$message
            .error(res.msg);
        this.progress = res.data
          .list;
        this.proTotal = res.data
          .total;
      },
      proSizeChange(newSize) {
        this.proQuery.pagesize =
          newSize;
        this.getProgress();
      },
      proCurChange(newPage) {
        this.proQuery.pagenum =
          newPage;
        this.getProgress();
      },
      async acceptPro(progress) {
        const confirmRes =
          await this
          .$confirm(
            "确认通过该条数据？",
            "提示", {
              confirmButtonText: "确定",
              cancelButtonText: "取消",
              type: "warning",
            }).catch((
              err) =>
            console
            .log(err));
        if (confirmRes !==
          "confirm") {
          return this
            .$message.info(
              "取消通过");
        }
        let formData =
          new FormData();
        formData.append("id",
          progress.id);
        const {
          data: res
        } = await this.$http
          .post(
            "progressSupervisor/acceptProgressExcel",
            formData
          );
        console.log(res);
        if (res.code !== 200)
          return this
            .$message
            .error(res
              .msg);
        this.$message.success(
          res.msg);
        this.getProgress();
      },
      rejectPro(progress) {
        this.curPro = progress;
        this.rejProDiaVisible =
          true;
      },
      rejProDiaClosed() {
        this.$refs.rejProForm
          .resetFields();
      },
      async rejPro() {
        let formData =
          new FormData();
        formData.append(
          "id", this
          .curPro.id);
        formData.append(
          "reason",
          this.curPro
          .reason);
        const {
          data: res
        } = await this
          .$http.post(
            "progressSupervisor/rejectProgressExcel",
            formData
          ); //-----------------------
        if (res.code !==
          200) return this
          .$message
          .error(res
            .msg);
        this.$message
          .success(res
            .msg);
        this.rejProDiaVisible =
          false;
        this.getProgress();
      },
      downLoadProExcel(excel) {
        let cid = excel.id;
        let url =
          this.$http
          .defaults
          .baseURL +
          "progressSupervisor/getProgressExcelById/" +
          cid;
        this.$message
          .warning(
            "在线预览 进度表 excel"
          );
        this.DownloadExcel(
          url);
      },
      showProExcel(excel) {
        let id = excel.id;
        let url =
          this.$http.defaults.baseURL +
          "progressSupervisor/getProgressExcelUrl/" +
          id;
        this.$http.get(url, {
          id
        }).then(res => {
          let doc =
            `https://view.officeapps.live.com/op/view.aspx?src=${this.$http.defaults.baseURL+res.data.msg}`
          window.open(doc)
        })
      },
    },
  };
</script>

<style lang="less" scoped>
  .title {
    font-size: 14px;
    color: #666;
    display: block;
    margin-right: 20px;
  }

  .colLeft {
    display: flex;
    align-items: center;
  }

  .select2 {
    width: 120px;
    margin-left: 20px;
  }
</style>
